import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';

interface ActionMenuProps {
  onPaymentDetailsClick: () => void;
  onViewCustomerClick: () => void;
  onCopyTransactionIdClick: () => void;
}

export const ActionMenu = ({
  onPaymentDetailsClick,
  onViewCustomerClick,
  onCopyTransactionIdClick,
}: ActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        height: '28px',
        '&:hover': {
          border: '0.5px solid black',
          borderRadius: 2,
        },
        display: 'flex',
        alignItems: 'center',
      }}
      className="menuOptionButton"
    >
      <IconButton
        onClick={handleClick}
        sx={{
          rotate: '90deg',
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: { borderRadius: 2 },
        }}
      >
        <Typography sx={{ fontSize: '12px', fontWeight: 700, ml: 2, color: '#596171' }}>Actions</Typography>
        <MenuItem onClick={onCopyTransactionIdClick} sx={{ color: '#0071E3', fontWeight: 600 }}>
          Copy transaction ID
        </MenuItem>
        <Divider />
        <Typography sx={{ fontSize: '12px', fontWeight: 700, ml: 2, color: '#596171' }}>Connections</Typography>
        <MenuItem onClick={onViewCustomerClick} sx={{ color: '#0071E3', fontWeight: 600 }}>
          View customer
        </MenuItem>
        <MenuItem onClick={onPaymentDetailsClick} sx={{ color: '#0071E3', fontWeight: 600 }}>
          View payment details
        </MenuItem>
      </Menu>
    </Box>
  );
};
