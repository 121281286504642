import { kebabCase } from 'lodash';

import { Link } from '@mui/material';
import { OrderItemSummaryBase } from '@one/api-models/lib/Membership/Account/OrderItemSummaryBase';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Membership/Account/Travel/Cruise/CruiseOrderItemSummary';

interface Props {
  orderItemSummary: OrderItemSummaryBase;
}

export const CancellationPolicy = ({ orderItemSummary }: Props) => {
  const buildCruiseCancellationPolicyLink = (orderItemSummary: OrderItemSummaryBase) => {
    const cruiseOrderItemSummary = orderItemSummary as CruiseOrderItemSummary;
    const docName = kebabCase(`${cruiseOrderItemSummary.productSummary.cruiseLine.name}  cancellation policies`);
    return `https://onemembersclub.com/cruises/rules-and-restrictions/${docName}`;
  };

  switch (orderItemSummary.$type) {
    case CruiseOrderItemSummary.$type:
      return (
        <Link
          sx={{ mb: 2 }}
          target="_blank"
          rel="noopener noreferrer"
          href={buildCruiseCancellationPolicyLink(orderItemSummary)}
        >
          Cruise line's cancellation policy
        </Link>
      );
    default:
      return null;
  }
};
