import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { Box, TextField } from '@mui/material';
import { GridCellParams, GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid';

const NameInput = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;

  const [firstName, setFirstName] = useState(item.value?.firstName || '');
  const [lastName, setLastName] = useState(item.value?.lastName || '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedApplyValue = useCallback(
    debounce((newItemValue) => {
      applyValue(newItemValue);
    }, 300),
    [applyValue],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'firstName' | 'lastName') => {
    const value = event.target.value;
    if (type === 'firstName') {
      setFirstName(value);
      debouncedApplyValue({ ...item, value: { ...item.value, firstName: value, lastName } });
    } else {
      setLastName(value);
      debouncedApplyValue({ ...item, value: { ...item.value, firstName, lastName: value } });
    }
  };

  useEffect(() => {
    setFirstName(item.value?.firstName || '');
    setLastName(item.value?.lastName || '');
  }, [item]);

  return (
    <Box sx={{ display: 'flex', gap: 1, mx: 1 }}>
      <TextField
        label="First Name"
        value={firstName}
        onChange={(e: any) => handleChange(e, 'firstName')}
        variant="standard"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Last Name"
        value={lastName}
        onChange={(e: any) => handleChange(e, 'lastName')}
        variant="standard"
        InputLabelProps={{ shrink: true }}
      />
    </Box>
  );
};

export const nameFilterOperators: GridFilterOperator[] = [
  {
    label: 'Equals',
    value: 'nameEquals',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value?.firstName && !filterItem.value?.lastName) {
        return null;
      }
      return (params: GridCellParams) => {
        const name = params.value as string;
        const firstName = filterItem.value.firstName;
        const lastName = filterItem.value.lastName;
        return (!firstName || name.startsWith(firstName)) && (!lastName || name.endsWith(lastName));
      };
    },
    InputComponent: NameInput,
  },
];
