import { parseISO } from 'date-fns';

import appConfig from 'config/appConfig';
import { ApiResponse, ErrorInfo } from 'models/Response';
import { UserData } from 'models/UserData';

export interface IApiClient {
  call(endpoint: string, body?: object, apiType?: ApiType): Promise<any>;
}

export interface FileResponse {
  blob: Blob;
  fileName: string;
}

export class ApiError extends Error {
  errors: ErrorInfo[];
  requestErrorId: string | undefined;

  constructor(message: string, errors: ErrorInfo[], requestErrorId?: string) {
    super(message);
    this.errors = errors;
    this.requestErrorId = requestErrorId;
  }
}

export enum ApiType {
  Admin,
  Sales,
}

const dateFormat =
  /^(?:[1-9]\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:Z|[+-][01]\d:[0-5]\d)$/;

function createDateObjects(obj: any) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      createDateObjects(obj[key]);
    } else if (typeof obj[key] === 'string' && dateFormat.test(obj[key])) {
      obj[key] = parseISO(obj[key]);
    }
  }
}

export class ApiClient implements IApiClient {
  private loadUser: () => UserData | null;
  private requestToken: () => Promise<string | undefined>;

  constructor(loadUser: () => UserData | null, requestToken: () => Promise<string | undefined>) {
    this.loadUser = loadUser;
    this.requestToken = requestToken;
  }

  async call(endpoint: string, body?: object, apiType?: ApiType): Promise<any> {
    const headers: HeadersInit = { 'content-type': 'application/json' };

    const token = await this.requestToken();

    if (token != null) {
      headers.Authorization = `Bearer ${token}`;
    }
    const config: RequestInit = {
      method: 'POST',
      headers: {
        ...headers,
      },
    };

    if (body) {
      const user = this.loadUser();
      config.body = JSON.stringify(user?.cultureCode == null ? body : { ...body, cultureCode: user.cultureCode });
    }

    let apiUrl;
    switch (apiType) {
      case ApiType.Sales:
        apiUrl = appConfig.salesApiUrl;
        break;

      default:
        apiUrl = appConfig.adminApiUrl;
        break;
    }

    const response = await window.fetch(`${apiUrl}/${endpoint}`, config);
    const requestErrorId = this.getRequestErrorId(config.headers);

    if (response) {
      if (!response.ok) {
        if (response.status === 404) {
          throw new ApiError(`404 Resource not found!`, []);
        }
        throw new ApiError(`${response.statusText} (${response.status})`, [], requestErrorId);
      }

      if (response?.headers?.get('content-type') != null) {
        const contentType = response!.headers.get('content-type')!;

        if (contentType.indexOf('application/json') > -1) {
          const data: ApiResponse<any> = await response.json();

          if (response.ok && (data.errors == null || data.errors.length === 0)) {
            createDateObjects(data);
            return data.value;
          }

          if (data.errors != null && data.errors.length > 0) {
            throw new ApiError('Api Error', data.errors, requestErrorId);
          }
        }

        if (contentType.indexOf('text/csv') > -1) {
          const contentDisposition = response?.headers?.get('Content-Disposition');
          const fileName =
            contentDisposition && contentDisposition.startsWith('attachment')
              ? /filename="(?<fileName>[^"]*)"/gim.exec(contentDisposition)?.groups?.fileName
              : undefined;

          const b = await response.blob();
          return { blob: b, fileName: fileName } as FileResponse;
        }
      }
    }

    throw new ApiError('Unexpected error', [], requestErrorId);
  }

  private getRequestErrorId(requestHeader: HeadersInit | undefined): string | undefined {
    try {
      if (requestHeader) {
        const requestId = (requestHeader as Headers).get('request-id') ?? undefined;

        if (requestId) {
          const errorIdSplit = requestId.replace('|', '').split('.');
          return errorIdSplit.length > 1 ? errorIdSplit[1] : errorIdSplit[0];
        }
      }
    } catch (e) {}

    return undefined;
  }
}
