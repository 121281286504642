import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { FilterResponse } from '@one/api-models/lib/Admin/PaymentPlan/FilterResponse';
import { PaymentPlanSummary } from '@one/api-models/lib/Admin/PaymentPlan/PaymentPlanSummary';

import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { useStyles } from 'components/_common/DataGrid/dataGridStyling';
import { PaymentPlanStatus } from 'components/_common/PaymentPlanStatus';
import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';
import { resetPaymentDataSliceState } from 'slices/paymentDataSlice';

import { EditPaymentPlanDialog } from './EditPaymentPlanDialog';

interface PaymentPlansSectionProps {
  isFetchingPaymentPlanList: boolean;
  paymentPlanListData?: FilterResponse;
  handleUpdatePaymentPlanSuccess: () => void;
  memberId: string;
  testId: string;
}

const pageSize = 5;

export const PaymentPlansSection = (props: PaymentPlansSectionProps) => {
  const { isFetchingPaymentPlanList, paymentPlanListData, handleUpdatePaymentPlanSuccess, memberId, testId } = props;

  const dispatch = useDispatch();
  const dataGridClasses = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };
  const [paymentPlanModalOpen, setPaymentPlanModalOpen] = useState<boolean>(false);
  const [selectedPaymentPlanId, setSelectedPaymentPlanId] = useState<string | number | null>(null);

  useEffect(() => {
    const paymentPlanId = searchParams.get('paymentPlanId');
    if (paymentPlanId) {
      setSelectedPaymentPlanId(paymentPlanId);
      setPaymentPlanModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaymentPlanModalOpen = (paymentPlan: any) => {
    setSelectedPaymentPlanId(paymentPlan.paymentPlanId);
    setPaymentPlanModalOpen(true);

    setSearchParams({ paymentPlanId: paymentPlan.paymentPlanId.toString() });
  };

  const handlePaymentPlanModalClose = () => {
    setPaymentPlanModalOpen(false);
    setSelectedPaymentPlanId(null);

    searchParams.delete('paymentPlanId');
    setSearchParams(searchParams);
    dispatch(resetPaymentDataSliceState());
  };

  const columns: GridColDef[] = [
    {
      field: 'paymentPlanId',
      headerName: '',
      sortable: false,
      flex: 4,
      cellClassName: 'noPL',
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
            <ClockIcon />
          </Box>
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body2" sx={{ pr: 1, fontWeight: 500 }}>
                {params.row.productName}
              </Typography>
              <PaymentPlanStatus status={params.row.status} />
            </Box>
            <Typography variant="body2" color="#6A7383">
              {params.row.total && `Total value: ${formatCurrency(params.row.total.amount, params.row.total.currency)}`}
              {params.row.totalPaid &&
                ` • Paid so far: ${formatCurrency(params.row.totalPaid.amount, params.row.totalPaid.currency)}`}
              {params.row.nextPaymentAmount &&
                ` • Next invoice on ${formatDate(
                  new Date(params.row.nextPaymentDueDate),
                  true,
                  'MMM dd',
                )} for ${formatCurrency(params.row.nextPaymentAmount.amount, params.row.nextPaymentAmount.currency)}`}
            </Typography>
            {params.row.orderNumber && (
              <Typography variant="body2" color="#adadad">
                Order Number: {params.row.orderNumber}
              </Typography>
            )}
            {params.row.externalReference && (
              <Typography variant="body2" color="#adadad">
                Payment Gateway Reference: {params.row.externalReference}
              </Typography>
            )}
          </Box>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      align: 'right',
      flex: 1,
      renderCell: (params: any) => (
        <Box className="actionButtons">
          <Tooltip title="Update payment plan">
            <IconButton
              aria-label="edit"
              size="small"
              disableRipple
              onClick={() => handlePaymentPlanModalOpen(params.row)}
              data-testid="PaymentPlanEditButton"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const renderPaymentPlansList = () => {
    let renderItem = null;
    if (isFetchingPaymentPlanList) {
      renderItem = <Skeleton variant="rectangular" width={300} height={25} />;
    } else if (paymentPlanListData?.paymentPlans && paymentPlanListData?.paymentPlans?.length > 0) {
      renderItem = (
        <DataGrid
          autoHeight
          rows={paymentPlanListData?.paymentPlans.map((item: PaymentPlanSummary) => ({
            ...item,
            id: item.paymentPlanId,
          }))}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: pageSize,
              },
            },
          }}
          getRowHeight={() => 'auto'}
          columnHeaderHeight={1}
          hideFooter={paymentPlanListData?.paymentPlans?.length <= pageSize}
          className={dataGridClasses.root}
          onRowClick={(param: any, event: any) => handlePaymentPlanModalOpen(param.row)}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',

              '& .actionButtons': {
                visibility: 'hidden',
              },

              '&:hover': {
                '& .actionButtons': {
                  visibility: 'visible',
                },
              },
            },
          }}
        />
      );
    } else {
      renderItem = (
        <Typography variant="body1" sx={{ mb: 1 }}>
          No payment plans available for this customer
        </Typography>
      );
    }

    return renderItem;
  };

  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Payment Plans" />
      <Grid container>
        <Grid item xs={12}>
          {renderPaymentPlansList()}
        </Grid>
      </Grid>

      {paymentPlanModalOpen && (
        <EditPaymentPlanDialog
          selectedPaymentPlanId={selectedPaymentPlanId}
          open={paymentPlanModalOpen}
          onClose={handlePaymentPlanModalClose}
          handleUpdatePaymentPlanSuccess={handleUpdatePaymentPlanSuccess}
          memberId={memberId}
          testId={testId}
        />
      )}
    </Box>
  );
};
