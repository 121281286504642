import { useSelector } from 'react-redux';

import { Box, useTheme } from '@mui/material';

import HBCIcon from 'assets/rewards/CCM.HBC.CCC.png';
import { ReactComponent as ECDIcon } from 'assets/rewards/ECD.ELX.ECR.svg';
import GBKIcon from 'assets/rewards/GBK.GBK.R.png';
import CVYIcon from 'assets/rewards/GPR.CVY.GMP.png';
import MBOIcon from 'assets/rewards/MBR.MBO.MS.png';
import { ReactComponent as CANIcon } from 'assets/rewards/PPR.CAN.HCN.svg';
import { ReactComponent as FCPHCIcon } from 'assets/rewards/PPR.FCP.HC.svg';
import { ReactComponent as FCPIcon } from 'assets/rewards/PPR.FCP.PPP.svg';
import { ReactComponent as PCUIcon } from 'assets/rewards/PPR.PCU.HCU.svg';
import { ReactComponent as CPRIcon } from 'assets/rewards/PRI.CPR.CP.svg';
import { ReactComponent as MCMIcon } from 'assets/rewards/PRI.MCM.MCR.svg';
import { ReactComponent as PPRIcon } from 'assets/rewards/PRI.PPR.PPP.svg';
import MGWIcon from 'assets/rewards/RSI.MGW.GWR.png';
import { ReactComponent as VICIcon } from 'assets/rewards/RYT.VIC.VTR.svg';
import { ReactComponent as UVCIcon } from 'assets/rewards/UVC.UVX.UVCR.svg';
import { ReactComponent as ASPIcon } from 'assets/rewards/VID.ASP.ASPR.svg';
import { AlternativeCurrency } from 'models/AlternativeCurrency';
import { Brand } from 'models/Brand';
import { Partner } from 'models/Partner';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';

type Props = {
  size?: string;
  color?: string;
  alternativeCurrency?: string;
  fallback?: React.ReactElement | null;
};

export const AlternativeCurrenciesIcon = ({ alternativeCurrency, size = '1rem', color, fallback = null }: Props) => {
  const theme = useTheme();
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);

  const partnerKey = activePartner?.key;
  const brandKey = activeBrand?.key;
  const renderAltCurrency = () => {
    switch (`${partnerKey}.${brandKey}.${alternativeCurrency}`.trim()) {
      case `${Partner.Princess}.${Brand.MedallionClassMarket}.${AlternativeCurrency.MedallionClassRewards}`:
        return <MCMIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PrincessPromotions}.${Brand.FutureCruisePackages}.${AlternativeCurrency.FutureCruiseCredits}`:
      case `${Partner.Princess}.${Brand.ClubPrincess}.${AlternativeCurrency.CruisePoints}`:
        return <CPRIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.Princess}.${Brand.PrincessPartnerRewards}.${AlternativeCurrency.PrincessPartnerPoints}`:
        return <PPRIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PrincessPromotions}.${Brand.FutureCruisePackages}.${AlternativeCurrency.PrincessPromoPoints}`:
        return <FCPIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PrincessPromotions}.${Brand.FutureCruisePackages}.${AlternativeCurrency.HotelCredits}`:
        return <FCPHCIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PrincessPromotions}.${Brand.FutureCruisePackagesCanada}.${AlternativeCurrency.HotelCreditsCanada}`:
        return <CANIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PrincessPromotions}.${Brand.FutureCruisePackagesUk}.${AlternativeCurrency.HotelCreditsUK}`:
        return <PCUIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.GuestBookings}.${Brand.GuestBookings}.${AlternativeCurrency.Rewards}`:
      case `${Partner.GuestBookings}.${Brand.TravelGuestBookings}.${AlternativeCurrency.TravelGuestBookingsRewards}`:
        return <ImgIcon src={GBKIcon} size={size} />;
      case `${Partner.GuestBookings}.${Brand.Vivri}.${AlternativeCurrency.Rewards}`:
        return <ImgIcon src={GBKIcon} size={size} />;
      case `${Partner.GuestBookings}.${Brand.GuestBookingsAMZ}.${AlternativeCurrency.Credits}`:
        return <ImgIcon src={GBKIcon} size={size} />;
      case `${Partner.GuestBookingsUK}.${Brand.GuestBookingsUK}.${AlternativeCurrency.GuestBookingsUKRewards}`:
        return <ImgIcon src={GBKIcon} size={size} />;
      case `${Partner.MagicBreak}.${Brand.MagicBreak}.${AlternativeCurrency.MagicStars}`:
        return <ImgIcon src={MBOIcon} size={size} />;
      case `${Partner.GrandPacificResorts}.${Brand.ClubVacay}.${AlternativeCurrency.GPRMarketPoints}`:
        return <ImgIcon src={CVYIcon} size={size} />;
      case `${Partner.RSI}.${Brand.MemberGetaways}.${AlternativeCurrency.RewardCredits}`:
        return <ImgIcon src={MGWIcon} size={size} />;
      case `${Partner.CCM}.${Brand.HotelsByCruisecom}.${AlternativeCurrency.CruiseComCredits}`:
        return <ImgIcon src={HBCIcon} size={size} />;
      case `${Partner.RedeemYourTravel}.${Brand.Victra}.${AlternativeCurrency.TravelRewards}`:
      case `${Partner.RedeemYourTravel}.${Brand.PrincessCUG}.${AlternativeCurrency.CUGTravelRewards}`:
        return <VICIcon height={size} />;
      case `${Partner.ElCid}.${Brand.ElCidExperiences}.${AlternativeCurrency.ElCidRewardPoints}`:
        return <ECDIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.UVC}.${Brand.UVCExperiences}.${AlternativeCurrency.UVCRewards}`:
        return <UVCIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.Vidanta}.${Brand.Aspirex}.${AlternativeCurrency.ASPRewards}`:
        return <ASPIcon height={size} fill={color ?? theme.palette.primary.main} />;
      default:
        return fallback;
    }
  };

  return renderAltCurrency();
};

type ImgIconProps = {
  src: string;
  size?: string;
};

const ImgIcon = ({ src, size }: ImgIconProps) => {
  const activeBrand = useSelector(selectActiveBrand);
  const brandKey = activeBrand?.key;
  return (
    <Box
      component="img"
      sx={{
        height: size,
      }}
      src={src}
      alt={`${brandKey}-Logo`}
    />
  );
};
