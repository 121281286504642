import { useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { Box, Button, CircularProgress } from '@mui/material';
import { OnBehalfOfMemberRequest } from '@one/api-models/lib/Admin/Members/Request/OnBehalfOfMemberRequest';
import { OnBehalfOfMemberResponse } from '@one/api-models/lib/Admin/Members/Response/OnBehalfOfMemberResponse';

import { ApiError } from 'apiAccess/api-client';
import { ReactComponent as CarIcon } from 'assets/icons/cars.svg';
import { ReactComponent as CruisesIcon } from 'assets/icons/cruises.svg';
import { ReactComponent as ExperiencesIcon } from 'assets/icons/experiences.svg';
import { ReactComponent as ResortsIcon } from 'assets/icons/resorts.svg';
import { ReactComponent as ShopIcon } from 'assets/icons/shop-1.svg';
import { ReactComponent as StaysIcon } from 'assets/icons/stays.svg';
import { ReactComponent as VillaIcon } from 'assets/icons/villas.svg';
import { ReactComponent as WineIcon } from 'assets/icons/wine.svg';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { Brand } from 'models/Brand';
import { OBODestinations } from 'models/customers/OBODestinations';
import { ProductType } from 'models/customers/ProductType';
import { selectActiveBrand } from 'slices/applicationDataSlice';

import { profileCtaStyle } from './styles';

type ButtonLabel = {
  [key: string]: string;
};
const buttonLabels: ButtonLabel = {
  'book-resort': 'Book a resort',
  'book-resort-FCP': 'Book a Premium Stay',
  'book-resort-CPR': 'Book a Premium Stay',
  'book-resort-CAN': 'Book a Premium Stay',
  'book-resort-PCU': 'Book a Premium Stay',
  'book-resort-GBK': 'Book a Premium Stay',
  'book-resort-CVY': 'Book a Club Resort',
  'book-resort-HBC': 'Book a Preferred Resort',
  'book-hotel': 'Book a hotel',
  'book-hotel-PPR': 'Book a hotel stay',
  'book-villa': 'Book a villa',
  'book-ocean-cruise': 'Book an Ocean Cruise',
  'book-river-cruise': 'Book a River Cruise',
  'book-week': 'Book a Week',
  'sell-wine': 'Sell Wine',
  'sell-merchandise': 'Sell Merchandise',
  'rent-car': 'Rent a car',
  'book-experiences': 'Book Experiences',
};

interface Props {
  memberKey: string;
  productTypes: string[];
  testId: string;
}

export const SalesSection: React.FC<Props> = ({ memberKey, productTypes, testId }) => {
  const { apiErrorHandler, showMessage } = useToastMessage();
  const { api } = useApiHelpers();
  const activeBrand = useSelector(selectActiveBrand);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onBehalfOfMutation = useMutation<OnBehalfOfMemberResponse, ApiError, OnBehalfOfMemberRequest, unknown>(
    (request) => {
      return api.members.onBehalfOf(request);
    },
    {
      onError: apiErrorHandler,
    },
  );

  const handleOboClick = async (product: string) => {
    try {
      setIsLoading(true);
      const response = await onBehalfOfMutation.mutateAsync({
        brandKey: activeBrand?.key ?? '',
        memberKey,
        destinationUrl: product,
      });
      window.open(response.redirectUrl);
    } catch (e) {
      showMessage('Cannot start session on behalf of customer', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <CircularProgress data-testid="LoadingSpinner" />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {productTypes.findIndex((item) => item === ProductType.Hotel) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations.Hotel)}
          data-testid={`${testId}BookAHotelButton`}
        >
          <StaysIcon style={{ width: '14px', marginRight: '10px', fill: '#ef6c00' }} />
          {buttonLabels[`book-hotel-${activeBrand?.key}`] ?? buttonLabels['book-hotel']}
        </Button>
      )}

      {productTypes.findIndex((item) => item === ProductType.OceanCruise) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations.Cruise)}
          data-testid={`${testId}BookAnOceanCruiseButton`}
        >
          <CruisesIcon style={{ width: '14px', marginRight: '10px', fill: '#0091ea' }} />
          {buttonLabels['book-ocean-cruise']}
        </Button>
      )}
      {productTypes.findIndex((item) => item === ProductType.RiverCruise) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations['Cruise.River'])}
          data-testid={`${testId}BookARiverCruiseButton`}
        >
          <DirectionsBoatIcon style={{ width: '14px', marginRight: '10px', fill: '#0091ea' }} />
          {buttonLabels['book-river-cruise']}
        </Button>
      )}
      {productTypes.findIndex((item) => item === ProductType.Resort) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations.Resort)}
          data-testid={`${testId}BookAResortButton`}
        >
          <ResortsIcon style={{ width: '14px', marginRight: '10px', fill: '#0091ea' }} />
          {buttonLabels[`book-resort-${activeBrand?.key}`] ?? buttonLabels['book-resort']}
        </Button>
      )}
      {productTypes.findIndex((item) => item === ProductType.ResortCollection) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations.FeaturedResort)}
          data-testid={`${testId}BookACollectionButton`}
        >
          <ResortsIcon style={{ width: '14px', marginRight: '10px', fill: '#0091ea' }} />
          {buttonLabels['book-resort']}
        </Button>
      )}
      {productTypes.findIndex((item) => item === ProductType.Villa) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations.Villa)}
          data-testid={`${testId}BookAVillaButton`}
        >
          <VillaIcon style={{ width: '14px', marginRight: '10px', fill: '#FBBC70' }} />
          {buttonLabels['book-villa']}
        </Button>
      )}
      {activeBrand?.key === Brand.ONE && productTypes.findIndex((item) => item === ProductType.Resort) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations.Week)}
          data-testid={`${testId}BookACollectionButton`}
        >
          <ResortsIcon style={{ width: '14px', marginRight: '10px', fill: '#FF7F50' }} />
          {buttonLabels['book-week']}
        </Button>
      )}
      {productTypes.findIndex((item) => item === ProductType.Wine) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations.Wine)}
          data-testid={`${testId}SellWineButton`}
        >
          <WineIcon style={{ width: '14px', marginRight: '10px', fill: '#c51162' }} />
          {buttonLabels['sell-wine']}
        </Button>
      )}

      {productTypes.findIndex((item) => item === ProductType.Merchandise) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations.Merchandise)}
          data-testid={`${testId}SellMerchandiseButton`}
        >
          <ShopIcon style={{ width: '14px', marginRight: '10px', fill: '#4caf50' }} />
          {buttonLabels['sell-merchandise']}
        </Button>
      )}

      {productTypes.findIndex((item) => item === ProductType.Car) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations.Car)}
          data-testid={`${testId}RentACarButton`}
        >
          <CarIcon style={{ width: '14px', marginRight: '10px', fill: '#005F85' }} />
          {buttonLabels['rent-car']}
        </Button>
      )}
      {productTypes.findIndex((item) => item === ProductType.Experience) !== -1 && (
        <Button
          variant="text"
          sx={{ ...profileCtaStyle }}
          onClick={() => handleOboClick(OBODestinations.Experience)}
          data-testid={`${testId}BookExperiencesButton`}
        >
          <ExperiencesIcon style={{ width: '14px', marginRight: '10px', fill: '#194073' }} />
          {buttonLabels['book-experiences']}
        </Button>
      )}
    </Box>
  );
};
