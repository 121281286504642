import { Box, Divider, Grid } from '@mui/material';
import { Program } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/Program';
import { ProgramItem } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/ProgramItem';

import { useFormat } from 'components/hooks/useFormat';
import { Typography } from 'styled';

interface ProgramItemsListProps {
  programs: Program[];
  variant?: 'caption' | 'body1';
}
export const ProgramItemsList = ({ programs, variant }: ProgramItemsListProps) => {
  const { formatDate, formatCurrency } = useFormat();

  const getItemsGroupedByActivation = (programItems: ProgramItem[]) => {
    return programItems.reduce((group: any, item) => {
      const { activeFrom } = item;
      const year = activeFrom ? new Date(activeFrom).getFullYear() : new Date().getFullYear();

      group[year] = group[year] ?? [];
      group[year].push(item);

      return group;
    }, {});
  };

  const renderProgramItems = (items: ProgramItem[]) => {
    const programItems = [];
    const groupedByActivation = getItemsGroupedByActivation(items);

    for (const year in groupedByActivation) {
      programItems.push(
        <Box ml={2} width="100%">
          <Grid item xs={2} my={1}>
            <Typography variant="body2">{year}</Typography>
          </Grid>
          <Grid item container xs={12} display={{ xs: 'none', sm: 'flex' }}>
            <Grid item xs={6} sm={3} lg={2}>
              <Typography variant="subtitle2">Type</Typography>
            </Grid>
            <Grid item xs={6} sm={3} lg={3}>
              <Typography variant="subtitle2">Active from</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={7} pb={{ xs: 1, sm: 0 }}>
              <Typography variant="subtitle2">Item</Typography>
            </Grid>
          </Grid>
          {groupedByActivation[year]
            .sort((a: ProgramItem, b: ProgramItem) => a.orderIndex - b.orderIndex)
            .map((item: ProgramItem, i: number) => (
              <Grid item container xs={12} key={`${item.type} ${i}`}>
                <Grid item xs={6} sm={3} lg={2}>
                  <Typography variant={variant}>{item.type}</Typography>
                </Grid>
                <Grid item xs={6} sm={3} lg={3}>
                  <Typography variant={variant}>
                    {item.activeFrom ? formatDate(new Date(item.activeFrom), true, 'dd MMM yyyy') : 'Active'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={7} pb={{ xs: 1, sm: 0 }}>
                  <Typography variant={variant}>{item.description}</Typography>
                </Grid>
              </Grid>
            ))}
        </Box>,
      );
    }
    return programItems;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Typography variant="subtitle2">Description</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">Qty</Typography>
        </Grid>
        <Grid item xs={3} textTransform="uppercase" justifyContent="flex-end" display="flex">
          <Typography variant="subtitle2">Amount</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {programs.map((program) => (
          <>
            <Grid item xs={8}>
              <Typography variant={variant}>{program.name}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant={variant}>1</Typography>
            </Grid>
            <Grid item xs={3} justifyContent="flex-end" display="flex">
              <Typography variant={variant}>{formatCurrency(program.price.amount, program.price.currency)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {renderProgramItems(program.items)}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
};
