import { Box } from '@mui/material';

import comingSoon from 'assets/coming-soon.png';
import { PageHeader } from 'components/_common/PageHeader';

export const ComingSoon = () => {
  return (
    <Box>
      <PageHeader testId="ComingSoon" />
      <Box
        sx={{
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            width: '200px',
            height: '200px',
            backgroundImage: `url(${comingSoon})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
        ></Box>
      </Box>
    </Box>
  );
};
