import { Fragment } from 'react';

import { Avatar, Box, Button, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import { SummaryLoadResponse } from '@one/api-models/lib/Membership/Account/SummaryLoadResponse';

import { CustomerStatus } from 'components/_common/CustomerStatus';
import { useFormat } from 'components/hooks/useFormat';
import { colorByInitial } from 'utils/avatar';

import { SummaryViewSkeleton } from './components/profile/skeletons/SummaryViewSkeleton';
import { assetCtaStyle } from './components/profile/styles';

interface CustomerSummaryProps {
  memberLoadData?: SummaryLoadResponse;
  isFetchingMemberLoadData: boolean;
  testId: string;
}
export const CustomerSummary = (props: CustomerSummaryProps) => {
  const { memberLoadData, isFetchingMemberLoadData, testId } = props;
  const { formatDate } = useFormat();
  const theme = useTheme();

  return (
    <Paper
      elevation={2}
      sx={{
        p: 3,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {!memberLoadData || isFetchingMemberLoadData ? (
        <SummaryViewSkeleton />
      ) : (
        <Fragment>
          <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} sm={6} md={5} lg={12}>
              <Grid container>
                <Grid item xs={2.5} sm={12} display="flex" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Avatar
                    sx={{
                      ...colorByInitial(memberLoadData.accountSummary.profileSummary.lastName || 'S'),
                      width: { sm: 44, md: 38, lg: 38 },
                      height: { sm: 44, md: 38, lg: 38 },
                      fontSize: '1.5em',
                      mr: 1,
                    }}
                  >
                    {memberLoadData.accountSummary.profileSummary.firstName.substring(0, 1)}
                  </Avatar>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h5">
                      {`${memberLoadData.accountSummary.profileSummary.firstName} ${memberLoadData.accountSummary.profileSummary.lastName}`}
                    </Typography>
                    <CustomerStatus status={memberLoadData.accountSummary.memberStatus} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sx={{ textAlign: { xs: 'left', sm: 'center', lg: 'left' } }}>
                      <Typography
                        variant="body1"
                        sx={{ mb: 1, color: `${theme.palette.primary.main}`, fontSize: '12px' }}
                      >
                        {memberLoadData.accountSummary.profileSummary.email.toLowerCase()}
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 1, color: `${theme.palette.primary.main}` }}>
                        {memberLoadData.accountSummary.profileSummary.mobilePhone}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={11} md={9} lg={12} sx={{ margin: { xs: 0, sm: '0 auto', lg: 0 } }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Divider sx={{ my: 1, width: '100%' }} />
                          <Box display="flex" justifyContent={{ xs: 'flex-start', sm: 'center', lg: ' flex-start' }}>
                            <Typography variant="caption" display="block" sx={{ color: '#adadad' }}>
                              Customer since:&nbsp;
                            </Typography>
                            <Typography variant="caption" display="block" sx={{ color: '#adadad' }}>
                              {formatDate(new Date(memberLoadData.accountSummary.createdOn), true, 'MMM dd, yyyy')}
                            </Typography>
                          </Box>
                          <Divider sx={{ my: 1, width: '100%' }} />
                        </Grid>
                        <Grid item xs={12}>
                          <Box display="flex" justifyContent={{ xs: 'flex-start', sm: 'center', lg: ' flex-start' }}>
                            <Typography variant="overline" display="block" sx={{ lineHeight: 1.4, mr: 1 }}>
                              MEMBERSHIP:&nbsp;
                            </Typography>
                            <Typography sx={{ lineHeight: 1.2, textAlign: 'center', textTransform: 'uppercase' }}>
                              {memberLoadData.accountSummary.membershipTier
                                ? memberLoadData.accountSummary.membershipTier.displayName
                                : '-'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box display="flex" sx={{ xs: 'flex-start', sm: 'center', lg: ' flex-start', mt: 1 }}>
                            <Typography variant="caption" display="block" sx={{ mr: 1, lineHeight: 1.3 }}>
                              Internal Membership Name:&nbsp;
                            </Typography>

                            <Typography
                              variant="caption"
                              sx={{ textAlign: 'center', textTransform: 'uppercase', lineHeight: 1.3 }}
                            >
                              {memberLoadData.accountSummary.membershipTier
                                ? memberLoadData.accountSummary.membershipTier.name
                                : '-'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} mt={1.5}>
                          <Box display="flex" justifyContent={{ xs: 'flex-start', sm: 'center', lg: ' flex-start' }}>
                            <Typography variant="caption">
                              <b>Expiry Date: &nbsp;</b>
                            </Typography>
                            <Typography variant="caption">
                              {memberLoadData.accountSummary.membershipTier
                                ? formatDate(
                                    new Date(memberLoadData.accountSummary.membershipTier.expiry),
                                    true,
                                    'MMM dd, yyyy',
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 1.7 }}>
                          <Box display="flex" justifyContent={{ xs: 'flex-end', sm: 'center', lg: 'flex-end' }}>
                            <Button
                              variant="outlined"
                              sx={{ ...assetCtaStyle }}
                              data-testid={`${testId}UpgradeButton`}
                              disabled
                            >
                              Upgrade
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Paper>
  );
};
