import { Chip } from '@mui/material';
import { OrderStatus as OrderStatusModel } from '@one/api-models/lib/Membership/Account/OrderStatus';

const statusSettings = [
  { id: 0, name: 'Unknown', backgroundColor: '#bbdffb', color: '#829caf' },
  { id: 1, name: 'New', backgroundColor: '#add8e6', color: '#2196f3' },
  { id: 2, name: 'Reserved', backgroundColor: '#FFC55C', color: '#b28940' },
  { id: 3, name: 'Ordered', backgroundColor: '#FFEDB5', color: '#b28940' },
  { id: 4, name: 'Paid', backgroundColor: '#a3c585', color: '#414e35' },
  { id: 5, name: 'Canceled', backgroundColor: '#6A7383', color: '#fff' },
  { id: 6, name: 'Failed', backgroundColor: '#ffcdd2', color: '#c62828' },
  { id: 7, name: 'Successful', backgroundColor: '#DDF6C7', color: '#2D681E' },
  { id: 8, name: 'Canceling', backgroundColor: '#ef5350', color: '#fff' },
];

export const OrderStatus = ({ status }: { status: OrderStatusModel }) => {
  const statusSetting = statusSettings.find((el) => el.id === status) || {
    name: status,
    backgroundColor: '#f5f5f5',
    color: '#000',
  };
  return (
    <Chip
      sx={{
        maxHeight: '20px',
        backgroundColor: statusSetting.backgroundColor,
        color: statusSetting.color,
        borderRadius: '4px',
        fontWeight: 500,
      }}
      size="small"
      label={statusSetting.name}
    />
  );
};
