export enum AlternativeCurrency {
  MedallionClassRewards = 'MCR',
  Rewards = 'R',
  GuestBookingsUKRewards = 'GBUR',
  Points = 'CVCP',
  OMCPoints = 'P',
  CruisePoints = 'CP',
  PrincessPromoPoints = 'PPP',
  PrincessPartnerPoints = 'PPP',
  HotelCredits = 'HC',
  FutureCruiseCredits = 'PFCC',
  HotelCreditsCanada = 'HCN',
  HotelCreditsUK = 'HCU',
  MagicStars = 'MS',
  GPRMarketPoints = 'GMP',
  Credits = 'C',
  RewardCredits = 'GWR',
  CruiseComCredits = 'CCC',
  TravelRewards = 'VTR',
  CUGTravelRewards = 'PTR',
  ElCidRewardPoints = 'ECR',
  UVCRewards = 'UVCR',
  TravelGuestBookingsRewards = 'TGBC',
  ASPRewards = 'ASPR',
}
