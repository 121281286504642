import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { BillingDetails } from '@one/api-models/lib/Admin/ProgramSales/Purchase/BillingDetails';
import { CompleteOrderRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderRequest';
import { CompleteOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderResponse';
import { InitOrderRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InitOrderRequest';
import { InitOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InitOrderResponse';
import { InstallmentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InstallmentPlan';
import { PaymentSource } from '@one/api-models/lib/Admin/ProgramSales/Purchase/PaymentSource';
import { PaymentToken } from '@one/api-models/lib/Admin/ProgramSales/Purchase/PaymentToken';
import { PaymentIntent, Stripe, StripeCardNumberElement } from '@stripe/stripe-js';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveAgency, selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import {
  selectInstallmentsPaymentPlan,
  selectSelectedCustomer,
  selectSelectedPrograms,
  setIsLoadingOrderPayment,
  setOrderConfirmation,
  setOrderKey,
  setPaymentError,
} from 'slices/salesOrderDataSlice';

export const useStripeProgramSales = () => {
  const dispatch = useDispatch();
  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const activeAgency = useSelector(selectActiveAgency);
  const installmentsPaymentPlan = useSelector(selectInstallmentsPaymentPlan);

  const initOrderMutation = useMutation<
    InitOrderResponse,
    ApiError,
    { billingDetails: BillingDetails; installmentsPlan?: InstallmentPlan },
    unknown
  >(
    async ({ billingDetails, installmentsPlan }) => {
      return await api.programSales.initOrder({
        billingDetails: billingDetails,
        paymentPlan: installmentsPlan
          ? {
              ...installmentsPlan,
            }
          : undefined,
        memberKey: selectedCustomer?.memberKey,
        programId: selectedPrograms[0]?.id,
        partnerKey: activePartner?.key,
        brandKey: activeBrand?.key,
        brandId: activeBrand ? parseInt(activeBrand?.key) : undefined,
        agencyKey: activeAgency?.key,
      } as InitOrderRequest);
    },
    {
      onSuccess: (value) => {
        if (value) {
          dispatch(setOrderKey(value.orderNumber));
        }
      },
      onError: apiErrorHandler,
    },
  );

  const completeOrderMutation = useMutation<
    CompleteOrderResponse,
    ApiError,
    { orderKey: string; transactionId: number; paymentConf?: PaymentIntent },
    unknown
  >(
    async ({ orderKey, transactionId, paymentConf }) => {
      const payment: PaymentSource = {
        amount:
          installmentsPaymentPlan && installmentsPaymentPlan?.downPaymentAmounts[0]
            ? installmentsPaymentPlan?.downPaymentAmounts[0]
            : selectedPrograms && selectedPrograms.length > 0
            ? selectedPrograms[0].price
            : { amount: 0, currency: 'USD', isEstimated: false },
        transactionId: transactionId,
        paymentIntentReference: paymentConf?.id,
        paymentMethodReference: paymentConf ? (paymentConf.payment_method as string) : undefined,
        isPaymentMethodDefault: true,
      };
      return await api.programSales.completeOrder({
        memberKey: selectedCustomer?.memberKey,
        orderNumber: orderKey,
        partnerKey: activePartner?.key,
        brandKey: activeBrand?.key,
        paymentSources: [payment],
        agencyKey: activeAgency?.key,
      } as CompleteOrderRequest);
    },
    {
      onSuccess: (value) => {
        if (value) {
          dispatch(setOrderConfirmation(value));
          dispatch(setOrderKey(undefined));
        }
      },
      onError: apiErrorHandler,
    },
  );

  const performCompleteOrderWithPayment = async (
    stripe: Stripe | null,
    card: StripeCardNumberElement,
    billingDetails: BillingDetails | undefined,
    installmentsPlan?: InstallmentPlan,
  ) => {
    if (!billingDetails) throw new Error('Failed to get billingDetails');

    dispatch(setPaymentError(undefined));
    const order = await initOrderMutation.mutateAsync({ billingDetails, installmentsPlan });
    await performPaymentMutation.mutateAsync({
      stripe,
      card,
      orderKey: order.orderNumber,
      paymentToken: order.paymentTokens[0],
      billingDetails,
    });
  };

  const performCompleteOrder = (orderKey: string, paymentToken: PaymentToken, paymentConf?: PaymentIntent) => {
    completeOrderMutation.mutate({ orderKey, transactionId: paymentToken?.transactionId, paymentConf });
  };

  const performCompleteOrderWithoutPayment = async (billingDetails: BillingDetails) => {
    const order = await initOrderMutation.mutateAsync({ billingDetails });
    performCompleteOrder(order.orderNumber, order.paymentTokens[0]);
  };

  // STRIPE Payment Section Begin
  const performPaymentMutation = useMutation<
    any,
    any,
    {
      stripe: Stripe | null;
      card: StripeCardNumberElement;
      orderKey: string;
      paymentToken: PaymentToken;
      billingDetails: BillingDetails;
    }
  >(
    async ({ stripe, card, orderKey, paymentToken, billingDetails }) => {
      if (!paymentToken || !paymentToken.secret) throw new Error('Failed to get clientSecret');

      if (card == null) throw new Error('Failed to get CardNumberElement');

      if (!stripe) throw new Error('Failed to initialize stripe');

      const { error, paymentIntent } = await stripe?.confirmCardPayment(paymentToken!.secret, {
        payment_method: {
          card: card,
          billing_details: {
            address: {
              line1: billingDetails?.streetAddress,
              line2: billingDetails?.aptNumber,
              postal_code: billingDetails?.zipCode,
              city: billingDetails?.city,
              state: billingDetails?.state,
              country: billingDetails?.country,
            },
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      performCompleteOrder(orderKey, paymentToken, paymentIntent as PaymentIntent);
    },
    {
      onError: (error: any) => {
        dispatch(setPaymentError(error.message));
      },
    },
  );
  // STRIPE Payment Section End

  useEffect(() => {
    dispatch(
      setIsLoadingOrderPayment(
        initOrderMutation.isLoading || performPaymentMutation.isLoading || completeOrderMutation.isLoading,
      ),
    );
  }, [dispatch, initOrderMutation.isLoading, performPaymentMutation.isLoading, completeOrderMutation.isLoading]);

  return {
    performCompleteOrderWithoutPayment,
    performCompleteOrderWithPayment,
  };
};
