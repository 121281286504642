import { IApiClient } from 'apiAccess/api-client';

export interface IAccessControlAccess {
  load(): Promise<AccessControlResponse>;
}

export class AccessControlAccess implements IAccessControlAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  load(): Promise<AccessControlResponse> {
    return this.apiClient.call('context');
  }
}

export type AccessControlPermissions =
  | 'Dashboard:View'
  | 'Customers:View'
  | 'Customers:Manage'
  | 'Sales:View'
  | 'Sales:Manage'
  | 'Sales.ProgramSales:View'
  | 'Sales.ProgramSales:Manage'
  | 'Sales.Orders:Edit'
  | 'Products:View'
  | 'Products:Manage'
  | 'Reports:View'
  | 'Reports.SalesReports:View'
  | 'Reports.CustomerReport:View'
  | 'Reports.TransactionReport:View'
  | 'Settings:View'
  | 'Settings:Manage'
  | 'Settings.Tags:View'
  | 'Settings.Tags:Manage'
  | 'Agency.AgentList:View'
  | 'Agency.Dashboard:View'
  | 'Agency.AgentList:CurrencyTransfer'
  | 'Agency.Transfer:View'
  | 'Marketing.Promotions:View'
  | 'Marketing.Promotions:Manage'
  | 'Reports.DelinquencyReport:View'
  | 'Customers:Manage:BulkEnroll'
  | 'Transactions.List:View';

export interface AccessControlResponse {
  roles: AccessControlRole[];
}

export interface AccessControlRole {
  name: string;
  permissions: string[];
}
