import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Button, Grid } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { useStripeProgramSales } from 'components/hooks/useStripeProgramSales';
import {
  PaymentOptions,
  selectBillingDetails,
  selectInstallmentsPaymentPlan,
  selectIsCompleteWithNoPayment,
  selectIsLoadingOrderPayment,
  selectPaymentError,
  selectPurchaseData,
  selectSelectedPaymentOption,
  setPaymentError,
} from 'slices/salesOrderDataSlice';
import { validateStripeCard } from 'utils/stripe';

import { PaymentBackdropLoading } from './components/PaymentBackdropLoading';
import { PaymentPlanForm } from './components/PaymentPlanForm';
import { InternalValidationErrors, StripeForm } from './components/StripeForm';

type PaymentForm = {};

export const PaymentDetailsStripe = () => {
  const dispatch = useDispatch();
  const { performCompleteOrderWithPayment } = useStripeProgramSales();

  const isLoading = useSelector(selectIsLoadingOrderPayment);
  const isCompleteWithNoPayment = useSelector(selectIsCompleteWithNoPayment);
  const selectedPaymentOption = useSelector(selectSelectedPaymentOption);
  const paymentError = useSelector(selectPaymentError);
  const billingDetails = useSelector(selectBillingDetails);
  const purchaseData = useSelector(selectPurchaseData);
  const installmentsPaymentPlan = useSelector(selectInstallmentsPaymentPlan);
  const testIdPrefix = 'PaymentDetailsStripe';

  const validatePaymentPlanForm = useRef(() => {
    return false;
  });

  const [cardErrors, setCardErrors] = useState<InternalValidationErrors>({
    cardNumberError: undefined,
    cardExpiryError: undefined,
    cardCvcError: undefined,
  });

  const stripe = useStripe();
  const elements = useElements();
  const paymentForm = useForm<PaymentForm>();

  const paymentSubmitHandler = async (data: PaymentForm) => {
    dispatch(setPaymentError(undefined));
    if (elements) {
      const { cardElement, creditCardErrors } = validateStripeCard(elements);
      setCardErrors(creditCardErrors);

      if (creditCardErrors.cardNumberError || creditCardErrors.cardExpiryError || creditCardErrors.cardCvcError) return;

      if (selectedPaymentOption === PaymentOptions.OneTime) {
        await performCompleteOrderWithPayment(stripe, cardElement, billingDetails, undefined);
      } else if (selectedPaymentOption === PaymentOptions.PaymentPlan) {
        let isPaymentEnabled = false;

        if (!!validatePaymentPlanForm && !!validatePaymentPlanForm.current) {
          isPaymentEnabled = await validatePaymentPlanForm.current();
        }

        if (isPaymentEnabled) {
          await performCompleteOrderWithPayment(stripe, cardElement, billingDetails, installmentsPaymentPlan);
        }
      }
    }
  };

  return (
    <>
      <FormProvider {...paymentForm}>
        <form onSubmit={paymentForm.handleSubmit(paymentSubmitHandler)}>
          {!!purchaseData?.paymentPlanConfig && (
            <Grid item xs={12} key="pf">
              <PaymentPlanForm validatePaymentPlanForm={validatePaymentPlanForm} testId={testIdPrefix} />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <StripeForm
              errors={cardErrors}
              error={paymentError}
              testId={testIdPrefix}
              resetError={() => {
                dispatch(setPaymentError(undefined));
              }}
            />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              type="submit"
              size="large"
              startIcon={<AttachMoneyIcon />}
              data-testid={`${testIdPrefix}ConfirmAndPayButton`}
            >
              Confirm and pay
            </Button>
          </Grid>
        </form>
      </FormProvider>

      <PaymentBackdropLoading
        isCompleteWithNoPayment={isCompleteWithNoPayment}
        isLoading={isLoading}
        // Only/always show submit payment message
        isSubmittingPayment={true}
      />
    </>
  );
};
