import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, FormControlLabel, Switch } from '@mui/material';
import {
  DataGrid,
  GridFilterModel,
  GridOverlay,
  GridPaginationModel,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { TransactionListResponse } from '@one/api-models/lib/Admin/Transaction/TransactionListResponse';

import { ApiError } from 'apiAccess/api-client';
import { dataGridStyle } from 'components/_common/DataGrid/dataGridStyling';
import { Pagination } from 'components/_common/DataGrid/Pagination';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { PageHeader } from 'components/_common/PageHeader';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import { selectAllBrandsEnabled, setAllBrandsEnabled } from 'slices/transactionsDataSlice';

import { Checkbox } from '../../_common/DataGrid/Checkbox';

import TransactionColumns from './components/TransactionColumns';

export const Transactions = () => {
  const defaultPageSize = 25;

  const dataGridClasses = dataGridStyle();

  const navigate = useNavigate();
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);

  const dispatch = useDispatch();

  const allBrandsEnabled = useSelector(selectAllBrandsEnabled);

  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();

  const { getEndDate, getStartDate } = useFormat();

  const [showSkeleton, setShowSkeleton] = useState(true);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [shouldResetPage, setShouldResetPage] = useState(false);

  //Filters
  const [filters, setFilters] = useState({
    statusFilter: [] as number[],
    customerEmailFilter: undefined as string | undefined,
    gatewayReferenceFilter: undefined as string | undefined,
    arnFilter: undefined as string | undefined,
    orderReferenceFilter: undefined as string | undefined,
    transactionIdsFilter: [] as number[],
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
    customerKey: undefined as string | undefined,
    customerFirstName: undefined as string | undefined,
    customerLastName: undefined as string | undefined,
  });
  const [prevFilters, setPrevFilters] = useState<GridFilterModel>({ items: [] });

  const clearFilters = () => {
    setFilters({
      statusFilter: [] as number[],
      customerEmailFilter: undefined,
      gatewayReferenceFilter: undefined,
      arnFilter: undefined,
      orderReferenceFilter: undefined,
      transactionIdsFilter: [] as number[],
      startDate: undefined,
      endDate: undefined,
      customerKey: undefined,
      customerFirstName: undefined,
      customerLastName: undefined,
    });
  };

  const handlePaymentDetailsClick = (params: any) => {
    const transactionId = params?.row.transactionId;
    navigate(`/transaction/${transactionId}`, {
      state: { transactionId },
    });
  };

  const handleViewCustomerClick = (params: any) => {
    navigate(`/customers/${params.row.customer.memberKey}`, { state: { refetch: true } });
  };

  useEffect(() => {
    setShowSkeleton(true);
  }, [activePartner, activeBrand]);

  const {
    data: transactions,
    isFetching,
    status,
  } = useQuery<TransactionListResponse, ApiError>(
    ['transactionsData', activeBrand, activePartner, page, pageSize, filters, allBrandsEnabled],
    () =>
      api.transaction.loadTransactionList({
        ...getFilterPayload(),
        listingCriteria: {
          skip: page * pageSize,
          take: pageSize,
        },
      }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
      onError: (error: ApiError) => addApiError(error),
      onSuccess: () => {
        setShowSkeleton(false);
        setShouldResetPage(false);
      },
    },
  );

  const handlePaginationChanges = (data: GridPaginationModel) => {
    setPageSize(data.pageSize);
    setPage(data.page);
  };

  const resetPage = () => {
    setPage(0);
    setPageSize(defaultPageSize);
    setShouldResetPage(true);
  };

  useEffect(() => {
    // onSuccess from useQuery is not being called at the initial render
    if (status === 'success') {
      setShowSkeleton(false);
    }
  }, [status]);

  const columns = TransactionColumns({ handlePaymentDetailsClick, handleViewCustomerClick });

  const handleFilterModelChange = (filters: GridFilterModel) => {
    const { field: filterType, value: filterValue } = filters?.items[0];
    setFilters((prevFilters) => {
      if (filterValue !== undefined) resetPage();
      switch (filterType) {
        case 'Status':
          return {
            ...prevFilters,
            statusFilter: filterValue !== undefined ? [filterValue] : [],
          };
        case 'customerEmail':
          return {
            ...prevFilters,
            customerEmailFilter: filterValue && (filterValue as string),
          };
        case 'gatewayReference':
          return {
            ...prevFilters,
            gatewayReferenceFilter: filterValue && (filterValue as string),
          };
        case 'arn':
          return {
            ...prevFilters,
            arnFilter: filterValue && (filterValue as string),
          };
        case 'orderReference':
          return {
            ...prevFilters,
            orderReferenceFilter: filterValue && (filterValue as string),
          };
        case 'transactionID':
          const transactionIdsArray =
            filterValue
              ?.split(' ')
              .map((id: string) => parseInt(id, 10))
              .filter((id: number) => !isNaN(id)) || [];
          return {
            ...prevFilters,
            transactionIdsFilter: transactionIdsArray,
          };
        case 'createdDateTime':
          return {
            ...prevFilters,
            startDate: filterValue?.start,
            endDate: filterValue?.end,
          };

        case 'customerKey':
          return {
            ...prevFilters,
            customerKey: filterValue && (filterValue as string),
          };
        case 'customer':
          return {
            ...prevFilters,
            customerFirstName: filterValue?.firstName && (filterValue.firstName as string),
            customerLastName: filterValue?.lastName && (filterValue.lastName as string),
          };
        default:
          return prevFilters;
      }
    });

    setPrevFilters(filters);
    if (prevFilters.items.length > 0)
      if (filters?.items[0]?.field !== prevFilters?.items[0]?.field) {
        filters.items[0].value = undefined;
        clearFilters();
      }
  };

  const getFilterPayload = () => {
    const defaultStartDate = getStartDate(new Date('2010-01-01'));

    const payload: any = {
      partnerKeys: allBrandsEnabled ? [] : [`${activePartner?.key}`],
      brandKeys: allBrandsEnabled ? [] : [`${activeBrand?.key}`],
      createdStartDate: filters.startDate ? getStartDate(new Date(filters.startDate)) : defaultStartDate,
      createdEndDate: filters?.endDate ? getEndDate(new Date(filters?.endDate)) : undefined,
      statuses: filters.statusFilter || [],
      transactionIds: filters.transactionIdsFilter || [],
    };

    if (filters.customerEmailFilter) {
      payload['customerEmail'] = filters.customerEmailFilter;
    }
    if (filters.gatewayReferenceFilter) {
      payload['transactionGatewayReference'] = filters.gatewayReferenceFilter;
    }
    if (filters.arnFilter) {
      payload['acquirerReferenceNumber'] = filters.arnFilter;
    }
    if (filters.orderReferenceFilter) {
      payload['orderNumber'] = filters.orderReferenceFilter;
    }
    if (filters.customerKey) {
      payload['customerKey'] = filters.customerKey;
    }
    if (filters.customerFirstName || filters.customerLastName) {
      payload['customerFirstName'] = filters.customerFirstName;
      payload['customerLastName'] = filters.customerLastName;
    }

    return payload;
  };

  return (
    <Box>
      <LoadingScreen open={showSkeleton} message={'Loading...'} />
      <PageHeader title="Transactions" testId={'Transactions'} />
      <Box>
        <DataGrid
          paginationMode="server"
          filterMode="server"
          onPaginationModelChange={handlePaginationChanges}
          onFilterModelChange={handleFilterModelChange}
          rows={transactions?.transactions.items ?? []}
          rowHeight={37}
          columns={columns}
          loading={isFetching || transactions == null}
          autoHeight
          onRowClick={(params) => handlePaymentDetailsClick(params)}
          getRowId={(row) => row.transactionId}
          rowCount={transactions?.transactions.itemCount ?? 0}
          columnVisibilityModel={{
            partner: allBrandsEnabled,
            brand: allBrandsEnabled,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                page: page,
                pageSize: pageSize,
              },
            },
          }}
          components={{
            LoadingOverlay: () => <GridOverlay sx={{ backgroundColor: 'white', opacity: 0.5 }} />,
            Pagination: () => (
              <Pagination
                disablePaginationButtons={transactions?.transactions.items.length === 0}
                resetPage={shouldResetPage}
              />
            ),
            BaseCheckbox: Checkbox,
            Toolbar: () => (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <GridToolbarFilterButton />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={allBrandsEnabled}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setAllBrandsEnabled(event.target.checked));
                          setShouldResetPage(true);
                        }}
                        inputProps={{
                          // eslint-disable-next-line
                          //@ts-ignore
                          'data-testid': `TransactionsAllBrandsSwitchInput`,
                        }}
                      />
                    }
                    label="Show transactions for all brands"
                  />
                </Box>
                <GridToolbarExport
                  csvOptions={{
                    fileName: `Transactions_${new Date().toISOString()}`,
                    allColumns: true,
                  }}
                />
              </Box>
            ),
          }}
          sx={{
            '& .MuiDataGrid-row': {
              '&:hover': {
                '& .menuOptionButton': {
                  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#FFFFFF',
                  borderRadius: 2,
                },
              },
            },
          }}
          componentsProps={{
            filterPanel: {
              filterFormProps: {
                operatorInputProps: {
                  disabled: true,
                  sx: { display: 'none' },
                },
              },
            },
          }}
          className={dataGridClasses.root}
        />
      </Box>
    </Box>
  );
};
