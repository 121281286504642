import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { TransactionStatus as TransactionStatusModel } from '@one/api-models/lib/Admin/Transaction/TransactionStatus';

import { dateRangeFilterOperators } from 'components/_common/DataGrid/FilterDateInput';
import { nameFilterOperators } from 'components/_common/DataGrid/FilterNameInput';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';

import { getCardLogo } from './common/CardLogo';
import { ActionMenu } from './ActionMenu';
import { TransactionStatus } from './TransactionStatus';

interface TransactionColumnsProps {
  handlePaymentDetailsClick: (params: any) => void;
  handleViewCustomerClick: (params: any) => void;
}

const TransactionColumns = ({ handlePaymentDetailsClick, handleViewCustomerClick }: TransactionColumnsProps) => {
  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();

  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const { showMessage } = useToastMessage();

  const columns: GridColDef[] = [
    {
      field: 'partner',
      headerName: 'Partner',
      sortable: false,
      filterable: false,
      width: 70,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (params: any) => params?.row?.customer?.partnerKey,
      renderCell: (params: any) => (
        <Typography sx={{ fontWeight: 600 }}>{params?.row?.customer?.partnerKey}</Typography>
      ),
    },
    {
      field: 'brand',
      headerName: 'Brand',
      sortable: false,
      filterable: false,
      width: 65,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (params: any) => params?.row?.customer?.brandKey,
      renderCell: (params: any) => <Typography sx={{ fontWeight: 600 }}>{params?.row?.customer?.brandKey}</Typography>,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      filterable: false,
      width: 85,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'center',
      type: 'number',
      valueGetter: (params: any) => params?.row?.amount?.amount,
      renderCell: (params: any) => (
        <Typography sx={{ fontWeight: 600, color: 'black' }}>
          {formatCurrency(params?.row?.amount?.amount, params?.row?.amount?.currency)}
        </Typography>
      ),
    },
    {
      field: 'Currency',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 40,
      type: 'string',
      disableColumnMenu: true,
      valueGetter: (params: any) => params?.row?.amount?.currency,
      renderCell: (params: any) => <>{params?.row?.amount?.currency}</>,
    },
    {
      field: 'Status',
      headerName: '',
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: TransactionStatusModel.New, label: 'New' },
        { value: TransactionStatusModel.Succeeded, label: 'Succeeded' },
        { value: TransactionStatusModel.Failed, label: 'Failed' },
        { value: TransactionStatusModel.Rejected, label: 'Rejected' },
        { value: TransactionStatusModel.Pending, label: 'Pending' },
        { value: TransactionStatusModel.PendingCapture, label: 'PendingCapture' },
        { value: TransactionStatusModel.Cancelled, label: 'Cancelled' },
        { value: TransactionStatusModel.Expired, label: 'Expired' },
        { value: TransactionStatusModel.PendingRefund, label: 'PendingRefund' },
        { value: TransactionStatusModel.Refunded, label: 'Refunded' },
        { value: TransactionStatusModel.Blocked, label: 'Blocked' },
        { value: TransactionStatusModel.Disputed, label: 'Disputed' },
        { value: TransactionStatusModel.EarlyFraudWarning, label: 'EarlyFraudWarning' },
        { value: TransactionStatusModel.Incomplete, label: 'Incomplete' },
        { value: TransactionStatusModel.PartiallyPaid, label: 'PartiallyPaid' },
        { value: TransactionStatusModel.PartiallyRefunded, label: 'PartiallyRefunded' },
        { value: TransactionStatusModel.Uncaptured, label: 'Uncaptured' },
        { value: TransactionStatusModel.WaitingOnFunding, label: 'WaitingOnFunding' },
        { value: TransactionStatusModel.Deleted, label: 'Deleted' },
      ],
      width: 156,
      disableColumnMenu: true,
      valueGetter: (params: any) => params?.row?.status,
      renderCell: (params: any) => <TransactionStatus status={params.row.status} />,
    },
    {
      field: 'paymentMethod',
      headerName: 'Payment Method',
      sortable: false,
      filterable: false,
      width: 130,
      disableColumnMenu: true,
      valueGetter: (params: any) => params?.row?.paymentMethod?.cardBrand || '',
      renderCell: (params: any) =>
        params.row.paymentMethod ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {getCardLogo(params?.row?.paymentMethod?.cardBrand)}
            <Typography mb={1.6} ml={-0.5} sx={{ fontSize: '24px' }}>
              ....
            </Typography>
            <Typography sx={{ ml: 0.5 }}>{params?.row?.paymentMethod?.cardLast4Digits}</Typography>
          </Box>
        ) : (
          <Typography>—</Typography>
        ),
    },
    {
      field: 'paymentProvider',
      headerName: 'Payment Provider',
      sortable: false,
      filterable: false,
      width: 130,
      disableColumnMenu: true,
      valueGetter: (params: any) => params.row.gatewayName || '',
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.gatewayName ? params.row.gatewayName : '—'}
        </Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      filterable: false,
      minWidth: 110,
      maxWidth: 150,
      flex: 1,
      type: 'string',
      disableColumnMenu: true,
      valueGetter: (params: any) => params.row.description,
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.description ? params.row.description : '—'}
        </Typography>
      ),
    },
    {
      field: 'customerEmail',
      headerName: 'Customer Email',
      sortable: false,
      minWidth: 180,
      maxWidth: 310,
      flex: 1,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (params: any) => params.row.customer.email,
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.customer.email}
        </Typography>
      ),
    },
    {
      field: 'createdDateTime',
      headerName: 'Date',
      sortable: false,
      width: 115,
      disableColumnMenu: true,
      filterOperators: dateRangeFilterOperators,
      type: 'date',
      valueGetter: (params: any) => new Date(params.row.createdDateTime),
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {formatDate(params.row.createdDateTime, false, 'dd MMM, HH:mm')}
        </Typography>
      ),
    },
    {
      field: 'declineReason',
      headerName: 'Decline Reason',
      sortable: false,
      filterable: false,
      width: 118,
      type: 'string',
      disableColumnMenu: true,
      renderCell: () => <>—</>,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableExport: true,
      renderCell: (params) => (
        <ActionMenu
          onPaymentDetailsClick={() => handlePaymentDetailsClick(params)}
          onViewCustomerClick={() => handleViewCustomerClick(params)}
          onCopyTransactionIdClick={() =>
            navigator.clipboard
              .writeText(params.row.transactionId)
              .then(() => {
                showMessage('Transaction ID copied to clipboard', 'success');
              })
              .catch(() => {
                showMessage('Failed to copy transaction ID', 'error');
              })
          }
        />
      ),
      width: 20,
      align: 'center',
    },
    {
      field: 'gatewayReference',
      headerName: 'Gateway Reference',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      minWidth: 0,
      maxWidth: 0,
      valueGetter: (params: any) => params.row.gatewayReference,
    },
    {
      field: 'transactionID',
      headerName: 'Transaction ID(s)',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      minWidth: 0,
      maxWidth: 0,
      valueGetter: (params: any) => params.row.transactionId,
    },
    {
      field: 'arn',
      headerName: 'ARN',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      minWidth: 0,
      maxWidth: 0,
      valueGetter: (params: any) => params.row.acquirerReferenceNumber,
    },
    {
      field: 'orderReference',
      headerName: 'Order Reference',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      minWidth: 0,
      maxWidth: 0,
      valueGetter: (params: any) => params.row.orderNumber,
    },
    {
      field: 'customerKey',
      headerName: 'Customer Key',
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      minWidth: 0,
      maxWidth: 0,
      valueGetter: (params: any) => params.row.customer.memberKey,
    },
    {
      field: 'customer',
      headerName: 'Customer',
      filterable: true,
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      minWidth: 0,
      maxWidth: 0,
      filterOperators: nameFilterOperators,
      valueGetter: (params: any) => `${params?.row?.customer.firstName} ${params?.row?.customer.lastName}`,
    },
  ];
  return columns;
};
export default TransactionColumns;
