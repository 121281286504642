import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Button = styled(MuiButton)(({ theme }) => ({
  textTransform: 'initial',
  borderRadius: 7,

  '&:active': {
    boxShadow: `0 0 0 4px ${theme.customPalette.input.borderFocus}`,
  },
  '&.MuiButton-outlined': {
    color: theme.palette.text.primary,
    borderColor: theme.customPalette.input.border,
    boxShadow: '0px 2px 5px 0px #3C425714, 0px 1px 1px 0px #0000001F',

    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: '0px 1px 1px 0px #0000001F, 0px 2px 5px 0px #3C425714, 0px 3px 9px 0px #3C425714',
    },
  },
  '&.MuiButton-contained': {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&.MuiButton-sizeSmall': {
    height: '24px',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 600,
  },

  '&.MuiButton-sizeMedium': {
    height: '30px',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
  },

  '&.MuiButton-sizeLarge': {
    height: '40px',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
  },
}));
