import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface CustomTheme {
    pricing: {
      accentColor: string;
      light: string;
      dark: string;
    };
    paymentPlan: {
      paymentStatusError: string;
      paymentStatusWarning: string;
      paymentStatusSuccess: string;
      chargeFeedback: string;
    };
    customPalette: {
      icons: {
        main: string;
        light: string;
        accent: string;
      };
      input: {
        border: string;
        borderFocus: string;
        placeholder: string;
      };
    };
  }
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export const createLocalTheme = (locale: any) =>
  createTheme({
    palette: {
      primary: {
        main: '#0071E3',
        light: '#0570DE',
        dark: '#1f8be0',
      },
      secondary: {
        light: '#ff4081',
        main: '#f50057',
        dark: '#c51162',
        contrastText: '#fff',
      },
      divider: '#E3E8EE',
      error: {
        main: '#DF1B41',
      },
      text: {
        primary: '#1A1F36',
        secondary: '#6A7383',
      },
    },
    customPalette: {
      icons: {
        main: '#6A7383',
        light: '#30313D',
        accent: '#0071E3',
      },
      input: {
        border: '#D5DBE1',
        borderFocus: '#0196ED5C',
        placeholder: '#a3acba',
      },
    },
    pricing: {
      accentColor: '#005F85',
      light: '#EFF8FF',
      dark: '#DBF1FF',
    },
    paymentPlan: {
      paymentStatusError: '#B2102F',
      paymentStatusWarning: '#f50057',
      paymentStatusSuccess: '#6CC13A',
      chargeFeedback: '#ddf6c780',
    },
    breakpoints: {
      values: {
        // extra-small
        xs: 0,
        // small
        sm: 490,
        // medium
        md: 768,
        // large
        lg: 1040,
        // extra-large
        xl: 1440,
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        '-system-ui',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Helvetica Neue',
        'Ubuntu',
        'sans-serif',
      ].join(','),
      h1: {
        fontSize: '28px',
        lineHeight: '32px',
        fontWeight: 700,
        color: '#000000',
      },
      h2: {
        fontSize: '26px',
        lineHeight: '30px',
        fontWeight: 700,
      },
      h3: {
        fontSize: '22px',
        lineHeight: '30px',
        fontWeight: 400,
      },
      h4: {
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: 700,
      },
      h5: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
      },
      h6: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 700,
      },
      body1: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
      },
      body2: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Helvetica Neue';
          font-style: normal;
          font-weight: normal;
          font-display: swap;
          src:local('Helvetica Neue'), url(/fonts/Helvetica/HelveticaNeue.woff2) format('woff2');
        },
        body, html {
          overscroll-behavior-y: none;
        }
        `,
      },
      MuiMenuItem: {
        defaultProps: {
          style: {
            display: 'flex',
            padding: '6px 16px',
            justifyContent: 'flex-start',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            fontWeight: 600,
            '&.upperCase': {
              textTransform: 'uppercase',
            },
          },
          contained: ({ theme }) => ({
            color: '#FFFFFF',
            border: '1px solid #06c',
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark,
            },
            '&:active': {
              backgroundColor: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark,
            },
            '&:disabled': {
              color: '#FFFFFF',
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              opacity: '50%',
            },
          }),
          text: ({ theme }) => ({
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: 'transparent',
              color: 'rgb(64, 68, 82)',
            },
            '&:focus': {
              color: theme.palette.primary.dark,
            },
            '&:disabled': {
              lineHeight: '20px',
            },
          }),
          sizeSmall: {
            lineHeight: '24px',
            paddingTop: '4px',
            paddingBottom: '4px',
          },
          sizeMedium: {
            lineHeight: '24px',
            paddingTop: '6px',
            paddingBottom: '6px',
            borderRadius: '10px',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow:
              '0px 2px 2px -1px rgb(0 0 0 / 10%), 0px 2px 3px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 5%)',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: '100%',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            '.MuiPickersDay-root.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
            '.MuiPickersDay-root.Mui-selected:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            '.MuiPickersDay-root:focus.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
            '.PrivatePickersYear-yearButton.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
            '.PrivatePickersYear-yearButton.Mui-selected:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            '.PrivatePickersYear-yearButton:focus.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
          }),
          elevation20: {
            boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            '&.muiStandardInput': {
              paddingBottom: '4px',
              paddingTop: '7px',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          },
          sizeSmall: {
            '& svg': {
              width: '16px',
              height: '16px',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.MuiSideDialog .MuiDialog-paper': {
              position: 'fixed',
              top: '-1px',
              right: 0,
              bottom: '-1px',
              transformOrigin: 'left center',
              overflow: 'hidden',
              outline: 'none',
              margin: '0px',
              maxHeight: '100%',

              [theme.breakpoints.down('md')]: {
                position: 'relative',
                width: '100%',
                height: '100%',
              },
            },
            '.MuiPickersDay-root.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
            '.MuiPickersDay-root.Mui-selected:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            '.MuiPickersDay-root:focus.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
            '.PrivatePickersYear-yearButton.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
            '.PrivatePickersYear-yearButton.Mui-selected:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            '.PrivatePickersYear-yearButton:focus.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
          }),
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: ({ theme }) => ({
            alignItems: 'center',
            boxShadow: 'rgb(235, 238, 241) 0px -1px 0px 0px inset',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '.MuiIconButton-root': {
              padding: '0px',
            },
          }),
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            borderRight: '1px solid rgb(235, 238, 241)',
            padding: '16px 24px !important',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '16px 24px',
            boxShadow: 'rgb(235, 238, 241) 0px 0px 0px 1px inset',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: '4px 8px 4px 8px',
          },
        },
      },
    },
  });

type Props = {
  children: React.ReactNode;
};

export const AppTheme = ({ children }: Props) => {
  const theme = createLocalTheme('enUS');

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider>
          <CssBaseline />
          {children}
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
