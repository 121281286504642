import { ReactElement, useMemo } from 'react';

import Close from '@mui/icons-material/Close';
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import { GenericOrderItemSummaryBase } from '@one/api-models/lib/Membership/Account/GenericOrderItemSummaryBase';
import { Order } from '@one/api-models/lib/Membership/Account/Order';
import { OrderItemSummaryBase } from '@one/api-models/lib/Membership/Account/OrderItemSummaryBase';
import { ReservationItemSummary } from '@one/api-models/lib/Membership/Account/Travel/Accommodation/ReservationItemSummary';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Membership/Account/Travel/Cruise/CruiseOrderItemSummary';

import { SectionTitle } from 'components/_common/SectionTitle';

import { CruiseOrderItemDetails } from './Assets/OrderDetails/CruiseOrderItemDetails';
import { CruisePricing } from './Assets/OrderDetails/CruisePricing';
import { Info } from './Assets/OrderDetails/Info';
import { ReservationOrderItemDetails } from './Assets/OrderDetails/ReservetionOrderItemDetails';

interface Props {
  order: Order;
  orderItemId: number;
  onClose: () => void;
}

export const OrderItemDetailsSection = ({ order, orderItemId, onClose }: Props) => {
  const orderItem = useMemo(() => {
    const orderItem = order.orderItems.find((orderItem: OrderItemSummaryBase) => orderItem.id === orderItemId);

    if (orderItem == null) return undefined;
    let orderItemType = 'generic';
    let name = 'Details';
    let detailsComponent: ReactElement | null = null;
    let pricingComponent: ReactElement | null = null;
    switch (orderItem.$type) {
      case CruiseOrderItemSummary.$type: {
        orderItemType = 'Cruise';
        const cruiseItem = orderItem as CruiseOrderItemSummary;
        name = cruiseItem.productSummary.name;
        detailsComponent = <CruiseOrderItemDetails orderItem={cruiseItem} />;
        pricingComponent = <CruisePricing order={order} />;
        break;
      }

      case ReservationItemSummary.$type: {
        orderItemType = 'Reservation';
        const reservationItem = orderItem as ReservationItemSummary;
        name = reservationItem.productSummary.name;
        detailsComponent = <ReservationOrderItemDetails orderItem={reservationItem} />;
        break;
      }
      default: {
        const genericOrderItem = orderItem as GenericOrderItemSummaryBase<{ name: string }>;
        if (genericOrderItem.productSummary?.name) {
          name = genericOrderItem.productSummary.name;
        }
      }
    }

    return { data: orderItem, type: orderItemType, name, detailsComponent, pricingComponent };
  }, [orderItemId, order]);

  return orderItem ? (
    <Paper elevation={3} sx={{ p: 2, my: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SectionTitle title={orderItem.name} variant="h5" />
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      {orderItem.detailsComponent}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <SectionTitle title="Supplier details" sx={{ mb: 0 }} variant="h6" />
            </Grid>
            <Grid item xs={6}>
              <Info label="Supplier / Agency" value={orderItem.data.supplierIdentifier} />
            </Grid>
            <Grid item xs={6}>
              <Info label="Booking reference" value={orderItem.data.supplierOrderReference} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          {orderItem.pricingComponent}
        </Grid>
      </Grid>
    </Paper>
  ) : null;
};
