import { Fragment } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import UndoIcon from '@mui/icons-material/Undo';
import { Chip } from '@mui/material';
import { TransactionStatus as TransactionStatusModel } from '@one/api-models/lib/Admin/Transaction/TransactionStatus';

interface TransactionStatusProps {
  status: TransactionStatusModel;
}

export const TransactionStatus = ({ status }: TransactionStatusProps) => {
  const statusSetting = statusSettings.find((el) => el.id === status) || {
    name: status,
    backgroundColor: '#f5f5f5',
    color: '#000',
  };

  const getIcon = () => {
    switch (status) {
      case 1:
        return <DoneIcon style={{ fill: '#006908' }} />;
      case 9:
        return <UndoIcon />;
      case 2:
        return <ClearIcon style={{ fill: '#c62828' }} />;
      case 6:
        return <UndoIcon />;
      default:
        return <Fragment />;
    }
  };

  return (
    <Chip
      sx={{
        maxHeight: '20px',
        backgroundColor: statusSetting.backgroundColor,
        color: statusSetting.color,
        borderRadius: '4px',
        fontWeight: 500,
        flexDirection: 'row-reverse',
        '& .MuiChip-icon': {
          marginLeft: ' 0px',
          marginRight: ' 4px',
        },
      }}
      size="small"
      label={statusSetting.name}
      icon={getIcon()}
    />
  );
};

const statusSettings = [
  { id: -1, name: 'Deleted', backgroundColor: '#d3d3d3', color: '#808080' },
  { id: 0, name: 'New', backgroundColor: '#add8e6', color: '#2196f3' },
  { id: 1, name: 'Succeeded', backgroundColor: '#D1FAB3', color: '#006908' },
  { id: 2, name: 'Rejected', backgroundColor: '#ef9a9a', color: '#d32f2f' },
  { id: 3, name: 'Failed', backgroundColor: '#ffcdd2', color: '#c62828' },
  { id: 4, name: 'Pending', backgroundColor: '#fff9c4', color: '#fbc02d' },
  { id: 5, name: 'PendingCapture', backgroundColor: '#ffecb3', color: '#ffa000' },
  { id: 6, name: 'Cancelled', backgroundColor: '#EBEEF1', color: '#596171' },
  { id: 7, name: 'Expired', backgroundColor: '#e0e0e0', color: '#9e9e9e' },
  { id: 8, name: 'PendingRefund', backgroundColor: '#ffcc80', color: '#fb8c00' },
  { id: 9, name: 'Refunded', backgroundColor: '#b2dfdb', color: '#00796b' },
  { id: 10, name: 'Blocked', backgroundColor: '#c5cae9', color: '#3949ab' },
  { id: 11, name: 'Disputed', backgroundColor: '#f8bbd0', color: '#c2185b' },
  { id: 12, name: 'EarlyFraudWarning', backgroundColor: '#ffab91', color: '#d84315' },
  { id: 13, name: 'Incomplete', backgroundColor: '#ffe082', color: '#ffa000' },
  { id: 14, name: 'PartiallyPaid', backgroundColor: '#dcedc8', color: '#689f38' },
  { id: 15, name: 'PartiallyRefunded', backgroundColor: '#c8e6c9', color: '#388e3c' },
  { id: 16, name: 'Uncaptured', backgroundColor: '#e1bee7', color: '#8e24aa' },
  { id: 17, name: 'WaitingOnFunding', backgroundColor: '#ffecb3', color: '#ffa000' },
];
