import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'qs';

import { Add as AddIcon, Star as StarIcon } from '@mui/icons-material';
import { Button, Divider, Grid, Paper } from '@mui/material';
import { MemberStatus } from '@one/api-models/lib/Admin/Members/MemberStatus';
import { MemberResponse as Customer } from '@one/api-models/lib/Admin/Members/Response/MemberResponse';
import { SummaryLoadResponse } from '@one/api-models/lib/Membership/Account/SummaryLoadResponse';

import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { setSelectedCustomer } from 'slices/customersDataSlice';

import { SalesSection } from './components/profile/SalesSection';
import { ServiceMenu } from './components/profile/ServiceMenu';
import { CustomerActionsSkeleton } from './components/profile/skeletons/CustomerActionsSkeleton';
import { profileCtaStyle } from './components/profile/styles';

interface CustomerActionsProps {
  memberId: string;
  memberLoadData?: SummaryLoadResponse;
  isFetchingMemberLoadData: boolean;
  testId: string;
}

type HistoryMemberState = {
  member?: Customer;
};

export const CustomerActions = (props: CustomerActionsProps) => {
  const { memberLoadData, isFetchingMemberLoadData, memberId, testId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasPermission } = useAppHelpers();

  const hasSellAProgramPermission = hasPermission(['Sales.ProgramSales:Manage']);
  const hasBookOnBehalfOfPermission = hasPermission(['Customers:OnBehalfOf']);
  const hasCustomerAccountPermission = hasPermission(['Customers:Manage']);

  const isExternalUser =
    memberLoadData?.accountSummary?.profileSummary?.holderAccountPartnerLoyaltyId ||
    memberLoadData?.accountSummary?.profileSummary?.holderAccountExternalMemberKey;

  const isInactiveUser = memberLoadData?.accountSummary?.memberStatus === MemberStatus.Inactive;

  const goToChooseProgram = () => {
    navigate({
      pathname: '/sales/new',
      search: stringify({
        memberKey: memberId,
      }),
    });
    dispatch(setSelectedCustomer(undefined));
  };

  return (
    <Paper
      elevation={2}
      sx={{
        px: 2,
        py: 3,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {!memberLoadData || isFetchingMemberLoadData ? (
        <CustomerActionsSkeleton />
      ) : (
        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={6} md={7} lg={12}>
            <Grid container sx={{ justifyContent: 'space-between', mt: { xs: 2, sm: 0 }, width: '100%' }}>
              {!hasSellAProgramPermission ? null : (
                <Grid item xs={12}>
                  <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                      variant="text"
                      startIcon={<StarIcon htmlColor="#ffd54f" />}
                      onClick={() => goToChooseProgram()}
                      sx={{ ...profileCtaStyle }}
                      data-testid={`${testId}SellAProgramButton`}
                    >
                      Sell a program
                    </Button>
                    <Button
                      variant="text"
                      startIcon={<AddIcon htmlColor="black" />}
                      onClick={() => goToChooseProgram()}
                      sx={{ ...profileCtaStyle }}
                      data-testid={`${testId}IncentiveButton`}
                    >
                      Incentive
                    </Button>
                  </Grid>
                </Grid>
              )}
              {isInactiveUser || !hasBookOnBehalfOfPermission ? null : (
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      my: 1,
                      width: '100%',
                      display: !hasSellAProgramPermission ? { xs: 'block', sm: 'none', lg: 'block' } : { xs: 'block' },
                    }}
                  />
                  <SalesSection
                    memberKey={memberId}
                    productTypes={memberLoadData.accountSummary.accountSummaryProperty.productTypes}
                    testId={testId}
                  />
                </Grid>
              )}
              {!hasCustomerAccountPermission ? null : (
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Divider
                    sx={{
                      my: 1,
                      width: '100%',
                      display:
                        !hasSellAProgramPermission && (isInactiveUser || !hasBookOnBehalfOfPermission)
                          ? { xs: 'block', sm: 'none', lg: 'block' }
                          : { xs: 'block' },
                    }}
                  />
                  <ServiceMenu
                    memberKey={memberId}
                    allowSendActivation={memberLoadData.accountSummary.allowSendActivation}
                    allowResetPassword={!isExternalUser}
                    testId={testId}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
