import { Program } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/Program';
import { BillingDetails } from '@one/api-models/lib/Admin/ProgramSales/Purchase/BillingDetails';
import { CalculatedInstallmentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CalculatedInstallmentPlan';
import { CompleteOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderResponse';
import { InstallmentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InstallmentPlan';
import { LoadResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/LoadResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { Customer } from 'models/customers/Customer';

export enum PaymentOptions {
  OneTime = 'one-time',
  PaymentPlan = 'payment-plan',
  RequestPayment = 'request-payment',
}

interface SalesOrderData {
  //TODO clean-up selectedProgram
  selectedProgram: Program | undefined;

  selectedPrograms: Program[];
  selectedCustomer: Customer | null;
  orderConfirmation: CompleteOrderResponse | undefined;
  purchaseData: LoadResponse | undefined;
  billingDetails: BillingDetails | undefined;
  selectedPaymentOption: PaymentOptions;
  installmentsPaymentPlan: InstallmentPlan | undefined;
  calculatedInstallmentPlan: CalculatedInstallmentPlan | undefined;
  orderKey: string | undefined;
  paymentError: string | undefined;
  isLoadingOrderPayment: boolean;
  isCompleteWithNoPayment: boolean;
  paymentProviderType: string | undefined;
}

const initialState: SalesOrderData = {
  selectedProgram: undefined,
  selectedPrograms: [],
  selectedCustomer: null,
  orderConfirmation: undefined,
  purchaseData: undefined,
  billingDetails: undefined,
  selectedPaymentOption: PaymentOptions.OneTime,
  installmentsPaymentPlan: undefined,
  calculatedInstallmentPlan: undefined,
  orderKey: undefined,
  paymentError: undefined,
  isLoadingOrderPayment: false,
  isCompleteWithNoPayment: false,
  paymentProviderType: undefined,
};

const salesOrderDataSlice = createSlice({
  name: 'salesOrderData',
  initialState,
  reducers: {
    setSelectedProgram: (state, action: PayloadAction<Program | undefined>) => {
      state.selectedProgram = action.payload;
    },
    setSelectedPrograms: (state, action: PayloadAction<Program[]>) => {
      state.selectedPrograms = action.payload;
    },
    setSelectedCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.selectedCustomer = action.payload;
    },
    setOrderConfirmation: (state, action: PayloadAction<CompleteOrderResponse | undefined>) => {
      state.orderConfirmation = action.payload;
    },
    setPurchaseData: (state, action: PayloadAction<LoadResponse | undefined>) => {
      state.purchaseData = action.payload;
    },
    setBillingDetails: (state, action: PayloadAction<BillingDetails | undefined>) => {
      state.billingDetails = action.payload;
    },
    setSelectedPaymentOption: (state, action: PayloadAction<PaymentOptions>) => {
      state.selectedPaymentOption = action.payload;
    },
    setInstallmentsPaymentPlan: (state, action: PayloadAction<InstallmentPlan | undefined>) => {
      state.installmentsPaymentPlan = action.payload;
    },
    setCalculatedInstallmentPlan: (state, action: PayloadAction<CalculatedInstallmentPlan | undefined>) => {
      state.calculatedInstallmentPlan = action.payload;
    },
    setOrderKey: (state, action: PayloadAction<string | undefined>) => {
      state.orderKey = action.payload;
    },
    setPaymentError: (state, action: PayloadAction<string | undefined>) => {
      state.paymentError = action.payload;
    },
    setPaymentProviderType: (state, action: PayloadAction<string | undefined>) => {
      state.paymentProviderType = action.payload;
    },
    setIsLoadingOrderPayment: (state, action: PayloadAction<boolean>) => {
      state.isLoadingOrderPayment = action.payload;
    },
    setIsCompleteWithNoPayment: (state, action: PayloadAction<boolean>) => {
      state.isCompleteWithNoPayment = action.payload;
    },
    resetSalesOrderDataSliceState: (state, action: PayloadAction<boolean>) => {
      state.installmentsPaymentPlan = undefined;
      state.calculatedInstallmentPlan = undefined;
      state.orderKey = undefined;
      state.paymentError = undefined;
      state.purchaseData = undefined;
      state.orderConfirmation = undefined;
      state.selectedPaymentOption = PaymentOptions.OneTime;
      state.selectedPrograms = [];
      state.isLoadingOrderPayment = false;
      state.isCompleteWithNoPayment = false;
      state.paymentProviderType = undefined;
      if (!action.payload) {
        state.selectedCustomer = null;
        state.billingDetails = undefined;
      }
    },
  },
});

export const {
  setSelectedProgram,
  setSelectedPrograms,
  setSelectedCustomer,
  setOrderConfirmation,
  setPurchaseData,
  setBillingDetails,
  setSelectedPaymentOption,
  setInstallmentsPaymentPlan,
  setCalculatedInstallmentPlan,
  setOrderKey,
  setPaymentError,
  setPaymentProviderType,
  setIsLoadingOrderPayment,
  setIsCompleteWithNoPayment,
  resetSalesOrderDataSliceState,
} = salesOrderDataSlice.actions;

export const selectSelectedProgram = (state: RootState) => state.salesOrderData.selectedProgram;
export const selectSelectedPrograms = (state: RootState) => state.salesOrderData.selectedPrograms;
export const selectSelectedCustomer = (state: RootState) => state.salesOrderData.selectedCustomer;
export const selectOrderConfirmation = (state: RootState) => state.salesOrderData.orderConfirmation;
export const selectPurchaseData = (state: RootState) => state.salesOrderData.purchaseData;
export const selectBillingDetails = (state: RootState) => state.salesOrderData.billingDetails;
export const selectSelectedPaymentOption = (state: RootState) => state.salesOrderData.selectedPaymentOption;
export const selectInstallmentsPaymentPlan = (state: RootState) => state.salesOrderData.installmentsPaymentPlan;
export const selectCalculatedInstallmentPlan = (state: RootState) => state.salesOrderData.calculatedInstallmentPlan;
export const selectOrderKey = (state: RootState) => state.salesOrderData.orderKey;
export const selectPaymentError = (state: RootState) => state.salesOrderData.paymentError;
export const selectPaymentProviderType = (state: RootState) => state.salesOrderData.paymentProviderType;
export const selectIsLoadingOrderPayment = (state: RootState) => state.salesOrderData.isLoadingOrderPayment;
export const selectIsCompleteWithNoPayment = (state: RootState) => state.salesOrderData.isCompleteWithNoPayment;

export default salesOrderDataSlice.reducer;
