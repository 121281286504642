import { useMutation, useQuery } from 'react-query';

import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { CalculateCancellationOutcomeRequest } from '@one/api-models/lib/Admin/Orders/Common/CalculateCancellationOutcomeRequest';
import { CalculateCancellationOutcomeResponse } from '@one/api-models/lib/Admin/Orders/Common/CalculateCancellationOutcomeResponse';
import { CancelOrderRequest } from '@one/api-models/lib/Admin/Orders/Common/CancelOrderRequest';
import { CancelOrderResponse } from '@one/api-models/lib/Admin/Orders/Common/CancelOrderResponse';
import { Order } from '@one/api-models/lib/Membership/Account/Order';

import { ApiError } from 'apiAccess/api-client';
import { AccessControlGuard } from 'components/_common/AccessControlGuard';
import { SectionTitle } from 'components/_common/SectionTitle';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';

import { CancellationForm } from './Assets/CancellationForm';
import { CancellationOutcome } from './Assets/CancellationOutcome';
import { CancellationPolicy } from './Assets/CancellationPolicy';

interface Props {
  order: Order;
  testId: string;
  isCancellationOpen: boolean;
  handleCloseCancellationForm: () => void;
  onOrderCancellationFinished: () => void;
}

export const CancellationSection = ({
  order,
  testId,
  onOrderCancellationFinished,
  handleCloseCancellationForm,
  isCancellationOpen,
}: Props) => {
  const { api } = useApiHelpers();
  const { apiErrorHandler, addMessage } = useToastMessage();

  const { data, isFetching } = useQuery<CalculateCancellationOutcomeResponse, ApiError>(
    ['orderCancellation', order.orderNumber],
    () => {
      const criteria = {
        orderNumber: order.orderNumber,
        orderItemIds: order.orderItems.map((orderItem) => orderItem.id),
        cultureCode: 'en-US',
        $Type: 'One.Api.Models.Admin.Products.LoadCriteria',
      } as CalculateCancellationOutcomeRequest;

      return api.sales.calculateCancellationOutcome(criteria);
    },
    {
      enabled: isCancellationOpen,
      keepPreviousData: true,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: apiErrorHandler,
    },
  );

  const {
    isLoading,
    data: cancelOrderResponse,
    mutate,
  } = useMutation<CancelOrderResponse, ApiError, CancelOrderRequest, unknown>(
    (request) => api.sales.cancelOrder(request),
    {
      mutationKey: 'cancelOrderMutation',
      onSuccess: (value: CancelOrderResponse) => {
        if (value.isSuccessful) {
          addMessage({
            label: 'Order cancelled with success.',
            severity: 'success',
          });
        }
        onOrderCancellationFinished();
      },
      onError: (error) => {
        apiErrorHandler(error);
        onOrderCancellationFinished();
      },
    },
  );

  if (order.isCancellationEnabled !== true) return null;

  const isNotCancellable = data == null || data.isCancellable !== true;

  const handleSubmitForm = (request: CancelOrderRequest): void => {
    mutate(request);
  };

  return (
    <AccessControlGuard permission={['Sales:Manage']}>
      {isCancellationOpen && (
        <>
          {isFetching || isLoading ? (
            <Box sx={{ height: 150 }} justifyContent="center" alignItems="center" display="flex">
              <CircularProgress data-testid="LoadingSpinner" />
            </Box>
          ) : (
            <Box mt={4}>
              {cancelOrderResponse != null ? (
                <Box mb={2}>
                  <Alert
                    variant="standard"
                    sx={{ mb: 2 }}
                    severity={cancelOrderResponse.isSuccessful ? 'success' : 'error'}
                  >
                    {cancelOrderResponse.isSuccessful ? (
                      'Order cancelled with success.'
                    ) : (
                      <>
                        <strong>An error has occurred!</strong> The order was not cancelled.
                      </>
                    )}
                  </Alert>

                  {cancelOrderResponse.messages != null && (
                    <ul>
                      {cancelOrderResponse.messages.map((message: string) => (
                        <li key={message}>{message}</li>
                      ))}
                    </ul>
                  )}
                </Box>
              ) : (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SectionTitle title="Cancel Order" />
                    {order.orderItems.length > 0 && <CancellationPolicy orderItemSummary={order.orderItems[0]} />}
                  </Box>
                  {isNotCancellable ? (
                    <Box mb={2} mx={2}>
                      <Typography variant="subtitle1">This order cannot be canceled.</Typography>
                    </Box>
                  ) : (
                    <>
                      <Box mb={2}>
                        <CancellationOutcome data={data} />
                        <CancellationForm
                          data={data}
                          order={order}
                          onCloseForm={handleCloseCancellationForm}
                          onSubmitForm={handleSubmitForm}
                          testId={testId}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          )}
        </>
      )}
    </AccessControlGuard>
  );
};
